import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import CookieConsent from "react-cookie-consent"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "../style/globalStyle"
import { theme } from "../style/theme"
import PageWrapper from "../style/pageWrapper"

const Layout = ({ children, className }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />
      <PageWrapper className={className}>
        <CookieConsent
          location="bottom"
          buttonText="Accepter"
          declineButtonText="Refuser"
          enableDeclineButton
          // flipButtons
          // disableStyles={true}
          style={{ background: "#141414", opacity: 0.9 }}
          buttonClasses="banner-button default-button"
          declineButtonClasses="banner-button default-button"
          buttonStyle={{ color: "#FFFFFF", background: "#ff5500", borderRadius: "5px", padding: "1.2rem 4rem" }}
          declineButtonStyle={{
            color: "#ff5500",
            background: "#FFFFFF",
            opacity: 0.5,
            borderRadius: "5px",
            padding: "1rem 2rem"
          }}
          expires={150}
          cookieName="gatsby-gdpr-google-analytics">
          <p>Ce site utilise Google Analytics. En continuant à naviguer, vous nous autorisez à déposer un cookie à des
            fins de mesure d'audience.</p>
        </CookieConsent>
        {children}
      </PageWrapper>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
