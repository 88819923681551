import styled from "styled-components"
import { mediaQueries } from "./mediaQueries"

const PageWrapper = styled.main`
  display: grid;
  grid-row-gap: 10rem;
  grid-template-rows: min-content repeat(2, min-content);
  grid-template-columns: [full-start] minmax(100px, 1fr)
  [center-start] minmax(1000px, 1120px) [center-end]
  minmax(100px, 1fr) [full-end];

  @media only screen and (${mediaQueries.tabLand}) {
    grid-template-columns: [full-start] minmax(50px, 1fr)
  [center-start] minmax(895px, 1100px) [center-end]
  minmax(50px, 1fr) [full-end];
  }

  @media only screen and (${mediaQueries.tabPort}) {
    grid-template-columns: [full-start] minmax(20px, 1fr)
  [center-start] minmax(724px, 950px) [center-end]
  minmax(20px, 1fr) [full-end];
    grid-row-gap: 6rem;
  }

  @media only screen and (${mediaQueries.phoneLand}) {
    grid-template-columns: [full-start] minmax(15px, 1fr)
  [center-start] minmax(280px, 740px) [center-end]
  minmax(15px, 1fr) [full-end];
  }
`

export default PageWrapper